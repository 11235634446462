// $(document).on('click', '#send', function(e){
//     var btn = $(this),
//         form = btn.parents('form'),
//         popup = form.parents('.popup'),
//         inputs = form.find('.required')
    
//     inputs.each(inputCheck)
//     e.preventDefault()

//     if(!form.find('.error').length){
//         var user_name = $.trim($('#user_name').val()),
//             phone = $.trim($('#phone')[0].mask.unmaskedValue),
//             comment = '',
//             results = {
//                 user_name,
//                 phone,
//                 comment
//             }
        
//         btn.attr('disabled', true)

//         sendAjax('add_order', results, function(){
//             btn.attr('disabled', false)
//             inputs.each(resetInput)
//             addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
//             closePopup(popup)
//         },function(res){
//             btn.attr('disabled', false)
//             addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
//             displayErrors(res.text_error)
//         })
//     }

//     e.preventDefault()
// })

$(document).on('click', '.send-request', function(e){
    var btn = $(this),
        form = btn.parents('form'),
        inputs = form.find('.required')
    
    inputs.each(inputCheck)
    e.preventDefault()

    if(!form.find('.error').length){
        var user_name = $.trim(form.find('.name').val()),
            phone = $.trim(form.find('.phone')[0].mask.unmaskedValue),
            comment = '',
            results = {
                user_name,
                phone,
                comment
            }
        
        btn.attr('disabled', true)

        sendAjax('add_order', results, function(){
            btn.attr('disabled', false)
            inputs.each(resetInput)
            addNotif('Спасибо!', 'Ваша заявка успешно отправлена.', 'success')
        },function(res){
            btn.attr('disabled', false)
            addNotif('Ошибка!', 'Что-то пошло не так.', 'error')
            displayErrors(res.text_error)
        })
    }

    e.preventDefault()
})

$('#send').click(function (e) {
    var btn = $(this),
        form = btn.parents('form'),
        inputs = form.find('.required');

    console.log(214214);

    e.preventDefault();
    inputs.each(inputCheck);
    console.log();
    if (!form.find('.error').length) {
        btn.attr('disabled', true);

        var user_name = $('#user_name').val(),
            phone = $('#phone_user').val(),
            message = $('#message').val(), 
            
            results = {
                user_name,
                phone,
                message,
            }
            console.log(results);
            

        sendAjax('add_order', results, function () {
            form.find('input, textarea').each(resetInput);
            btn.attr('disabled', false);
            addNotif('Заявка', 'Заявка успешно отправлено !', 'success');
            closePopup($('.popup'))
        })
        closePopup(popup)
    }
})
$('#send_cont').click(function (e) {
    var btn = $(this),
        form = btn.parents('form'),
        inputs = form.find('.required');

    console.log(214214);

    e.preventDefault();
    inputs.each(inputCheck);
    console.log();
    if (!form.find('.error').length) {
        btn.attr('disabled', true);

        var user_name = $('#user_name').val(),
            phone = $('#phone_user').val(),
            message = $('#message').val(), 
            
            results = {
                user_name,
                phone,
                message,
            }
            console.log(results);
            

        sendAjax('add_order', results, function () {
            form.find('input, textarea').each(resetInput);
            btn.attr('disabled', false);
            addNotif('Заявка', 'Заявка успешно отправлено !', 'success');
            closePopup($('.popup'))
        })
        closePopup(popup)
    }
})